import { useEffect, useState } from "react"
import axios from "axios"

const TESTFREAKS_UNAVAILABLE_COUNTRIES = ["fr"]

const useTestFreakReviewsApi = (
  productId,
  country,
  recursivelyGetAll = false
) => {
  const [reviewData, setReviewData] = useState()

  /**
   * optionally can recursively gets all reviews through next_page_url in RESPONSE
   * as the repsonse has 10 reviews per page
   */
  const appendReviewsFromUrl = async (url, reviewsArr = []) => {
    const reviews = await axios.get(url)
    if (reviews?.data?.reviews) {
      reviewsArr.push(...reviews.data.reviews)
    }
    if (reviews?.data?.next_page_url && recursivelyGetAll) {
      return appendReviewsFromUrl(reviews.data.next_page_url, reviewsArr)
    }
  }

  useEffect(() => {
    const fetchTestFreakApi = async () => {
      const countryCode = TESTFREAKS_UNAVAILABLE_COUNTRIES.includes(country)
        ? "fr"
        : country
      const res = await axios.get(
        `https://js.testfreaks.com/onpage/flowlife.com-${countryCode}/reviews.json?key=${productId}&url_key=familyid`
      )
      const allReviews = []
      if (res.data.your_review_url) {
        await appendReviewsFromUrl(res.data.your_review_url, allReviews)
      }
      setReviewData({ ...res.data, reviews: allReviews })
    }

    fetchTestFreakApi()
  }, [])

  return reviewData
}

export default useTestFreakReviewsApi
